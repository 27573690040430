import dynamic from 'next/dynamic';
import Image from "next/image";
import { NavLink } from '../../ui';
import styles from './Header.module.css';
import { useGlobalContext } from '../../../lib/context'
import {  LoginType } from '../../../lib/constants';
import { IPageMenu } from '../../../interfaces/IPages';
import { removeCookie } from "../../../lib/helpers/Cookie";
import { ILoginData } from "../../../interfaces/ILogin";
import logo from '../../../public/assets/images/interior-logo.svg';
import storeIcon from '../../../public/assets/images/store-icon.gif';
import { pushRoute } from '@/lib/helpers/common';
import { staticLoader } from '@/lib/helpers/imageLoader';
import React from 'react';


type props = {
  HeaderData: IPageMenu[],
  isUserLogin: ILoginData | null
}
export default function Header({ HeaderData, isUserLogin }: props) {
  const { props: { countryCode, isMobileView, pageURL } } = useGlobalContext();
  const siteURL = process.env.SITE_URL + "/" || "/";
  const baseURL = `${siteURL}${countryCode}`;
  async function LogOut() {
    removeCookie(LoginType.LOGIN);
    removeCookie('cart');
    removeCookie("setCartUpdated");
    if (pageURL.includes('/dashboard')) {
      pushRoute(`${siteURL}`);
    } else {
      pushRoute(pageURL)
    }
  }
  return <>
    <header className={styles.header} >
      <div className="container">
        {
          isMobileView ?
            <div className={styles.setMobileHeader}>
              <div className={styles.logoLogin}>
                <div className={styles.logoBox}>
                  <NavLink href={baseURL.split("/in")[0].TrimTrailingSlash()} >
                    <Image
                      width={84}
                      height={37}
                      src={logo}
                      alt="Interior Company"
                      loading='eager'
                      className="img-responsive"
                    />
                  </NavLink>
                  <div className={`${styles.mkStore} ${styles.mkStoreMobile}`} onClick={() => pushRoute(`${siteURL}online-store`)}>
                    <Image
                      width={25}
                      height={25}
                      src={storeIcon}
                      loader={staticLoader}
                      alt="Marketplace"
                      loading='eager'
                      className="img-responsive"
                    />
                    <NavLink href={`${siteURL}online-store`}>
                      <p className={"fbold textpink font10"}>Explore<span className={"fbold textblue font12"}>STORE</span></p>
                    </NavLink>
                  </div>
                </div>
                <div className={styles.mobLogin}> <NavLink href={baseURL + "/free-home-interior-cost-estimator"} className={` ${styles.loginButton} secondaryBtn`}>Get Free Estimate</NavLink></div>
              </div>
            </div>
            :
            <div className={styles.headContainer}>
              <div className={styles.weblogoBox}>
                <NavLink href={baseURL.split("/in")[0].TrimTrailingSlash()} >
                  <Image
                    width={84}
                    height={37}
                    src={logo}
                    alt="Interior Company"
                    loading='eager'
                    className="img-responsive"
                  />
                </NavLink>
              </div>
              <div className={styles.nav}>
                <ul >
                  {HeaderData && HeaderData.map((menu, idx) =>
                    <li className={menu && menu.child && menu.child.length > 0 ? styles.addArrow : ""} key={idx} id={menu.pageName.textToSlug()}>
                      {menu.pageUrl != null ?
                        <NavLink href={menu.pageUrl} className={styles.arrow}>
                          <span >{menu.pageName}</span>
                        </NavLink>
                        :
                        <span className={styles.arrow}>{menu.pageName}</span>
                      }
                      {menu && menu.child && menu.child.length > 0 && <i className={`arrow  down ${styles.mtn2} `}></i>}
                    </li>
                  )}
                </ul>
                <ul className={`${styles.navLogin}`}>
                  <li>
                    <div className={styles.mkStore} onClick={() => pushRoute(`${siteURL}online-store`)}>
                      <Image
                        width={25}
                        height={25}
                        src={storeIcon}
                        loader={staticLoader}
                        alt="Marketplace"
                        loading='eager'
                        className="img-responsive"
                      />
                      <NavLink href={`${siteURL}online-store`} >
                        <p className={"fbold textpink font10"}>Explore<span className={"fbold textblue font12"}>STORE</span></p>
                      </NavLink>
                    </div>
                  </li>
                  <li><NavLink href={baseURL + "/free-home-interior-cost-estimator"} className={` ${styles.bookConsultation} secondaryBtn`}>Get Free Estimate</NavLink></li>
                  <li className={styles.loginRegister}>
                    {(isUserLogin && Object.keys(isUserLogin).length != 0) ? <>
                      <figure className={styles.clentHeaderThmbnl} >
                        <Image src={'/assets/images/loginAvatar.svg'} width={30} height={30} className={'img-responsive'} alt={"Login"} />
                      </figure>
                      <div className={styles.loginDropDown}>
                        <ul>
                          <li className={styles.loginList}><NavLink href={baseURL + `/dashboard?type=my-projects`}>My Projects</ NavLink></li>
                          <li className={styles.loginList}><NavLink href={baseURL + `/dashboard?type=care-request`}>Care Request</ NavLink></li>
                          <li className={styles.loginList}><NavLink href={baseURL + `/dashboard?type=my-orders`}>Orders</ NavLink></li>
                          <li className={styles.loginList}><NavLink href={baseURL + `/dashboard?type=my-wishlist`}>Wishlist</ NavLink></li>
                          <li className={styles.loginList} onClick={() => LogOut()}>Logout</li>
                        </ul>
                      </div>
                    </>
                      : <NavLink href={baseURL + "/login"}>
                        <figure className={styles.clentHeaderThmbnl} >
                          <Image src={'/assets/images/loginOffAvatar.svg'} width={30} height={30} className={'img-responsive'} alt={"Login"} />
                        </figure>
                      </NavLink>}
                  </li>
                </ul>
              </div>
            </div>
        }
      </div>
    </header>
  </>;
}